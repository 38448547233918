import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Grid, Paper, Typography, Box } from '@material-ui/core';
import { Theme, withStyles } from '@material-ui/core/styles';
import { MdArrowForward as ArrowForwardIcon } from 'react-icons/md';

const styles = (theme: Theme) => ({
  root: {
    cursor: 'pointer',
    flexGrow: 1,
  },
  boardImage: {
    maxWidth: '34px',
  },
  boardListItemHeader: {},
  container: {
    paddingLeft: '20px',
    borderRadius: '4px',
    backgroundColor: '#FFFFFF',
  },
  boardListItemIcon: {
    fontSize: '24px',
  },
  boardListItemWrapper: {
    padding: '10px',
    marginBottom: '10px',
  },
  imageBox: {
    backgroundColor: '#F7F7F7',
    padding: '14px',
    textAlign: 'center',
  },
  activeBox: {
    marginTop: '6px',
    backgroundColor: '#FBC993',
    lineHeight: '14px',
    fontSize: '12px',
    padding: '4px 6px',
    width: 'fit-content',
    borderRadius: '3px',
  },
  activeBoxNoCount: {
    backgroundColor: '#3E3C3B80',
  },
});

const BoardListItem = (props: any) => {
  const { boardName, classes, linkTo, reviewCount } = props;
  return (
    <div className={classes.root}>
      <Paper elevation={2} className={classes.boardListItemWrapper} variant="elevation">
        <Grid
          container
          spacing={2}
          alignItems="center"
          onClick={linkTo}
          className={classes.container}
        >
          <Grid item xs={10} sm={11}>
            <Typography variant="h4" color="textPrimary" className={classes.boardListItemHeader}>
              {boardName}
            </Typography>
            <Box className={clsx(classes.activeBox, reviewCount < 1 && classes.activeBoxNoCount)}>
              <Typography
                variant="subtitle2"
                className={reviewCount < 1 ? 'text-white' : 'text-text'}
              >
                {reviewCount} Active
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2} sm={1}>
            <ArrowForwardIcon className={clsx(classes.boardListItemIcon, 'text-primary')} />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

BoardListItem.propTypes = {
  boardName: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  imageBase64: PropTypes.string,
  imageMime: PropTypes.string,
  linkTo: PropTypes.func.isRequired,
  reviewCount: PropTypes.number.isRequired,
};

export default withStyles(styles)(BoardListItem);
