import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Container, Grid, Theme, Typography, withStyles } from '@material-ui/core';
import { navigate } from 'gatsby';

import Skeleton from '@material-ui/lab/Skeleton';

import '../style/page.scss';
import clsx from 'clsx';
import { useBoards } from '../adapters';
import Page from '../components/page';
import BoardListItem from '../components/BoardListItem';
import Logo from '../components/icons/Logo';

const styles = (theme: Theme) => ({
  topSection: {
    backgroundColor: '#5f673b',
    marginTop: '8px',
    paddingBottom: '10px',
  },
  biggerText: {
    fontSize: '16px',
  },
  topText: {
    color: theme.palette.common.white,
  },
  bottomContainer: {
    paddingTop: theme.spacing(4),
  },
  bottomText: {
    color: '#3E3C3B',
  },
  button: {
    padding: '15px 50px',
    width: '100%',
  },
});

const IndexPage = (props: any) => {
  // eslint-disable-next-line no-unused-vars
  const { classes, data } = props;
  const { getBoards, isFetchingBoards } = useBoards();
  const [boards, setBoards] = useState([]);

  useEffect(() => {
    (async () => {
      const res = await getBoards({
        getActiveReviewCounts: true,
        getImages: true,
      });
      if (res && res.data) {
        setBoards(res.data);
      }
    })();
  }, []);

  const getListOfBoards = () => {
    return boards.map(({ id, name, abbreviation, imageBase64, imageMime, reviewCount }) => {
      return (
        <BoardListItem
          key={id}
          boardName={name}
          imageBase64={imageBase64}
          imageMime={imageMime}
          reviewCount={reviewCount}
          linkTo={() =>
            navigate(
              `/submissions/board/${encodeURIComponent(
                abbreviation.toLowerCase(),
              )}?activeReviews=true`,
            )
          }
        />
      );
    });
  };

  return (
    <Page isFetching={false} container={false} isStaticHome={true}>
      <>
        <Box className={classes.topSection}>
          <Container maxWidth="lg">
            <Grid
              container
              spacing={2}
              justify="space-between"
              alignItems="center"
              className={'rootContainer'}
            >
              <Grid item xs={12} md={7} className={classes.biggerText}>
                <Typography variant="h1" className={classes.topText}>
                  Welcome to the Environmental Impact Screening Committee Online Registry
                </Typography>
                <Typography variant="body1" className={classes.topText}>
                  Welcome to the Online Registry which facilitates the EISC with environmental
                  screening of development activities proposed for both the onshore and offshore
                  areas of the Inuvialuit Settlement Region.
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box p={2} style={{ textAlign: 'center' }}>
                  <Logo />
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Container maxWidth="lg" className={classes.bottomContainer}>
          <Grid
            container
            spacing={2}
            justify="space-between"
            alignItems="flex-start"
            className={'rootContainer'}
          >
            <Grid item xs={12} md={4} className={clsx(classes.biggerText, classes.bottomText)}>
              <Typography variant="h2">Active Project Submissions</Typography>
              <Typography variant="body1" color="textPrimary">
                This page contains links to active project submissions undergoing the EISC process.
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                size="large"
                className={classes.button}
                onClick={() => navigate('/submissions/')}
                disableElevation
              >
                See All Project Submissions
              </Button>
            </Grid>
            <Grid item xs={12} md={7}>
              {isFetchingBoards ? (
                <div>
                  <Skeleton variant="text" height={40} />
                  <Skeleton variant="text" height={40} />
                  <Skeleton variant="text" height={40} />
                  <Skeleton variant="text" height={40} />
                  <Skeleton variant="text" height={40} />
                </div>
              ) : (
                getListOfBoards()
              )}
            </Grid>
          </Grid>
        </Container>
      </>
    </Page>
  );
};

IndexPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(IndexPage);
